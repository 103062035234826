const firebaseConfig = {
  projectId: "the-paradigm-83e9d",
  appId: "1:868657033135:web:e6ba37ef7648a54db8495a",
  databaseURL:
    "https://the-paradigm-83e9d-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "the-paradigm-83e9d.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyDtOnZoyVtrxTRzqMxhLpjGoqkQsnN4Lb4",
  authDomain: "the-paradigm-83e9d.firebaseapp.com",
  messagingSenderId: "868657033135",
  measurementId: "G-1ML2810NP5",
};

export default firebaseConfig;
